import { protectedResources } from "../../msalAuthConfig";
import { apiInstance } from "../apiInstance";

const headers = {
    'Content-Type': 'application/json',
};

const memberAnalyticsApi = {
    uploadFile: async (file, onUploadProgress) => {
        const { companyId, uploaderUserId, cohortName, originalFileName, training, marketType, market } = file;
        const formData = new FormData();

        formData.append("file", file);
        formData.append("companyId", companyId);
        formData.append("cohortName", cohortName);
        formData.append("originalFileName", originalFileName);
        formData.append("uploaderUserId", uploaderUserId);
        formData.append("marketType", marketType);
        formData.append("market", market);
        formData.append("training", training);

        return apiInstance({
            method: 'post',
            url: `${protectedResources.apiMemberAnalytics.endpoint}/upload`,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            onUploadProgress
        })
    },
    uploadFileEditCohort: async (file, onUploadProgress) => {
        const { companyId, uploaderUserId, cohortName, originalFileName, cohortId } = file;
        const formData = new FormData();

        formData.append("file", file);
        formData.append("companyId", companyId);
        formData.append("cohortId", cohortId);
        formData.append("cohortName", cohortName);
        formData.append("originalFileName", originalFileName);
        formData.append("uploaderUserId", uploaderUserId);

        return apiInstance({
            method: 'put',
            url: `${protectedResources.apiCohortManagement.endpoint}/${cohortId}/upload`,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            onUploadProgress
        })
    },

    getMembersData: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCohortManagement.endpoint}/${id}/members`,
            headers,
        })
    },

    updateMember: (memberData) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiMemberAnalytics.endpoint}/members`,
            headers,
            data: memberData
        })
    },
    GetCohortUpdateFiles: async () => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiMemberAnalytics.endpoint}/member-update-files`,
            headers,
        });
    },
    UpdateCohortUpdateInput: async (file, onUploadProgress) => {
        const { companyId, cohortId, uploaderUserId, cohortUpdateName, originalFileName, isLocalFile, size, processingStatus, status } = file;
        const formData = new FormData();

        formData.append("file", file);
        formData.append("companyId", companyId);
        formData.append("cohortId", cohortId);
        formData.append("cohortUpdateName", cohortUpdateName);
        formData.append("originalFileName", originalFileName);
        formData.append("uploaderUserId", uploaderUserId);
        formData.append("isLocalFile", isLocalFile);
        formData.append("size", size);
        formData.append("processingStatus", processingStatus);
        formData.append("status", status);

        return apiInstance({
            method: 'post',
            url: `${protectedResources.apiMemberAnalytics.endpoint}/cohort-update/upload`,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            onUploadProgress
        })
    },
}

export default memberAnalyticsApi;
