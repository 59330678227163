import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import SharedForm from "../../components/ui/Form";
import FileProgress from "../../components/ui/FileProgress";
import withAuth from "../../components/hoc/withAuth";
import messages from "../../utils/helper/messages";
import { isObjectEmpty, isValidName, radioOptionValues } from '../../utils/utils';
import { ALERT_TYPE, FILE_TYPE, MIME_TYPES, POSITIONS, ALIGN, FILE_TYPE_VALUE, COHORT_FILE_STATUS } from '../../constants';
import { AlertContext } from "../../context/AlertContext";
import Loader from "../../components/ui/Loader";
import api from '../../services/api/memberAnalyticsService';

const UpdateCohortModal = ({ data, show, onCancel }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [cohortDetails, setCohortDetails] = useState(null);
    const [selectedFileType, setSelectedFileType] = useState('');
    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(null);

    const { showAlert } = useContext(AlertContext);
    const currentUser = useSelector((state) => state.user.currentUser);
    const allowedFileTypes = [MIME_TYPES.CSV];

    useEffect(() => {
        if (cohortDetails?.documentUpload) {
            setSelectedFileType(cohortDetails.documentUpload);
        }
    }, [cohortDetails?.documentUpload]);

    useEffect(() => {
        if (data) {
            setCohortDetails({ name: data.name });
        }
    }, [data]);

    const arr = useMemo(() => [
        {
            key: 'name',
            labelName: 'Cohort Name',
            placeholderName: 'Cohort Name',
            type: 'text',
            disabled: true,
            required: false
        },
        {
            key: 'cohortUpdateName',
            labelName: 'Update Name',
            placeholderName: 'Update Name',
            type: 'text',
        },
        {
            type: 'radio',
            key: 'documentUpload',
            labelName: 'Document Upload',
            optionValues: radioOptionValues(FILE_TYPE_VALUE, false, true),
            maxLength: 12,
            resetFields: ['file', 'systemFile'],
        },
        {
            key: 'file',
            type: 'file',
            allowedTypes: allowedFileTypes,
            fileNameKey: 'fileName',
            hidden: selectedFileType !== FILE_TYPE.Local,
            labelName: 'Local File',
            required: selectedFileType === FILE_TYPE.Local,
        },
        {
            key: 'systemFile',
            type: 'systemFile',
            allowedTypes: allowedFileTypes,
            fileNameKey: 'systemFileName',
            hidden: selectedFileType !== FILE_TYPE.System,
            labelName: 'System File',
            required: selectedFileType === FILE_TYPE.System,
            fileApi: api.GetCohortUpdateFiles
        }
    ], [selectedFileType]);

    const handleSuccess = (fileObj) => {
        showAlert(`${fileObj.originalFileName} ${messages.success.cohortUpload}`, ALERT_TYPE.SUCCESS);
        setIsLoading(false);
        setProgress(0);
        onHideCallback();
    };

    const handleError = (error) => {
        setIsLoading(false);
        setProgress(0);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleSubmit = async (formData) => {
        setIsLoading(true);
        const file = cohortDetails.file;
        let fileObj = new File(
            [file],
            `${currentUser.companyId}_${formData.cohortUpdateName}_${selectedFileType === FILE_TYPE.Local ? file.name : cohortDetails.fileName}`,
            { type: selectedFileType === FILE_TYPE.Local ? file.type : MIME_TYPES.CSV }
        );

         fileObj.companyId = currentUser.companyId;
         fileObj.uploaderUserId = currentUser.id;
         fileObj.cohortUpdateName = formData.cohortUpdateName;
         fileObj.originalFileName = cohortDetails.fileName;
         fileObj.processingStatus = 'W';
         fileObj.status = COHORT_FILE_STATUS.UPLOADED;
         fileObj.cohortId = data.id;
         fileObj.isLocalFile = selectedFileType === FILE_TYPE.Local;
         fileObj.cohortId = data.id;

        try {
            await api.UpdateCohortUpdateInput(fileObj, (event) => {
                progressRef.current = Math.round((100 * event.loaded) / event.total);
                setProgress(progressRef.current);
                if (progressRef.current === 100) {
                    setCohortDetails(null);
                    setProgress(0);
                }
            }).then(() => handleSuccess(fileObj)).catch(handleError);
        } catch (error) {
            handleError(error);
        }
    };

    const handleCohortDetailsChange = (update) => {
        setCohortDetails((details) => ({ ...details, ...update }));
    };

    const onHideCallback = () => {
        setCohortDetails(null);
        setFile("");
        onCancel();
    };

    const handleInputChange = (name, value) => {
        if (name === "documentUpload") {
            setSelectedFileType(value);
            handleCohortDetailsChange({ documentUpload: value, fileName: '', file: null, systemFile: null });
        } else if (name === "file") {
            handleCohortDetailsChange({ fileName: value.name, file: value});
            setFile(value);
        } else if (name === "systemFile") {
            handleCohortDetailsChange({ fileName: value.name, systemFile: value });
            setFile(value);
        } else {
            handleCohortDetailsChange({ [name]: value });
        }
    };

    const isValidCohortInfo = () => !isObjectEmpty(cohortDetails) && isValidName(cohortDetails.cohortUpdateName);

    return (
        <Modal show={show} backdrop="static" onHide={onHideCallback} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Update Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2 position-relative">
                    {data === null && <Loader />}
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={cohortDetails}
                        onSubmit={handleSubmit}
                        onCancel={onHideCallback}
                        onInputChanged={handleInputChange}
                        loading={isLoading}
                        disabled={!isValidCohortInfo}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                    />
                    {progress > 0 && <FileProgress file={file} progress={progress} wrapperStyle="mt-2" />}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default withAuth(UpdateCohortModal);
