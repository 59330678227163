import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import styles from './SystemFileModal.module.scss';
import { ALERT_TYPE } from '../../constants';
import Loader from './Loader';
import { AlertContext } from '../../context/AlertContext';
import messages from '../../utils/helper/messages';

const SystemFileModal = ({
    item,
    onFileSelect,
    fileApi,
}) => {
    const { showAlert } = useContext(AlertContext);
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileList, setFileList] = useState(null);

    useEffect(() => {
        if (showModal) {
            getCohortSystemFiles();
        }
    }, [showModal]);

    const handleOpenModal = () => setShowModal(true);

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedFile(null);
    };

    const handleSendFile = () => {
        if (!selectedFile) {
            showAlert('Please select a file.');
            return;
        }
        onFileSelect(item.key, selectedFile);
        handleCloseModal();
    };

    const getCohortSystemFiles = () => {
        fileApi()
            .then((res) => setFileList(res.data || []))
            .catch(handleError);
    };

    const handleError = (error) => {
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    return (
        <>
            <Button
                variant="secondary"
                className={styles.chooseButton}
                onClick={handleOpenModal}>
                Choose File
            </Button>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                dialogClassName='w-100'
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Select System File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-end mt-2">
                        <Button
                            variant="outline-primary"
                            onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            className="ms-2"
                            variant="primary"
                            onClick={handleSendFile}
                            disabled={!selectedFile || !selectedFile.name}>
                            Select File
                        </Button>
                    </div>
                    {fileList === null ? (
                        <Loader size={2} />
                    ) : (
                        <ListGroup className={`${styles.fileList} overflow-y-auto mt-4`}>
                            {fileList.length > 0 ? (
                                fileList.map((file, index) => (
                                    <ListGroup.Item
                                        key={`file-${index}`}
                                        active={selectedFile?.name === file?.name}
                                        onClick={() => setSelectedFile(file)}
                                        style={{ cursor: 'pointer' }}>
                                        {file.name}
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <div>No files available</div>
                            )}
                        </ListGroup>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SystemFileModal;
