import api from '../../services/api/cohortService';
import memberApi from '../../services/api/memberAnalyticsService';
import keyMirror from '../../utils/keyMirror';
import { convertToSensivityData } from '../../utils/utils';

export const actionTypes = keyMirror(
    {
        GET_ALL_COHORTS: null,
        GET_ARCHIVED_COHORTS: null,
        GET_ALL_COHORTS_NOTIFICATIONS: null,
        ARCHIVE_COHORT_NOTIFICATIONS: null,
        GET_MEMBERS_BY_COHORT_ID: null,
        DELETE_COHORTS: null,
        UPDATE_COHORT_MEMBERS: null,
        SCHEDULE_COHORTS: null,
        GET_SENSITIVITY_SCORES: null,
        RESET_RISK_SCORES: null,
        DELETE_NOTIFICATION: null
    },
    'Cohort'
);

// Action Creators

export const getCohorts = (id) => dispatch => {

    api.getCohortData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ALL_COHORTS,
            payload: data,
        });
    });
}

export const getCohortsByStatus = (id, status) => dispatch => {
    api.getCohortDataByStatus(id, status).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ARCHIVED_COHORTS,
            payload: data,
        });
    });
}

export const getCohortNotifications = (id) => dispatch => {

    api.getCohortNotifications(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ALL_COHORTS_NOTIFICATIONS,
            payload: data,
        });
    });
}


export const archiveCohortNotifications = (notificationIds, successHandler) => dispatch => {
    api.archiveCohortNotifications(notificationIds).then(() => {
        dispatch({
            type: actionTypes.ARCHIVE_COHORT_NOTIFICATIONS,
            payload: notificationIds.idList,
        });
        successHandler();
    });
}

export const getNotificationsByApplication = (id, application) => dispatch => {
    api.getNotificationsByApplication(id, application).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ALL_COHORTS_NOTIFICATIONS,
            payload: data,
        });
    });
}

export const getMembersByCohortId = (id) => dispatch => {
    memberApi.getMembersData(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_MEMBERS_BY_COHORT_ID,
            payload: data,
        });
    });
}

export const archiveCohort = (cohortId, successHandler, errorHandler) => dispatch => {
    api.archiveCohort(cohortId).then(() => {
        dispatch({
            type: actionTypes.DELETE_COHORTS,
            payload: cohortId,
        });
        successHandler();
    }).catch(function (error) {
        errorHandler(error);
    });
}

export const archiveNotification = (notification, successHandler) => dispatch => {
    api.updateNotificationsStatus(notification.id, notification.status).then(() => {
        dispatch({
            type: actionTypes.DELETE_NOTIFICATION,
            payload: notification.id,
        });
        successHandler();
    });
}

export const scheduleCohort = (cohortId, successHandler) => dispatch => {
    api.scheduleCohort(cohortId).then(() => {
        dispatch({
            type: actionTypes.SCHEDULE_COHORTS,
            payload: cohortId,
        });
        successHandler();
    });
}

export const updateMember = (memberData) => dispatch => {
    memberApi.updateMember(memberData).then(() => {
        dispatch({
            type: actionTypes.UPDATE_COHORT_MEMBERS,
            payload: memberData,
        });
    });
}

export const getCohortRiskScores = (cohortId) => dispatch => {
    api.getCohortRiskScores(cohortId).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_SENSITIVITY_SCORES,
            payload: data,
        });
    });
};

export const resetCohortRiskScores = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_RISK_SCORES
    });
}

// Reducer

export const initialState = {
    cohorts: null,
    archiveCohorts: null,
    notifications: null,
    members: null,
    sensitivityScores: null,
};

export default function cohortReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_ALL_COHORTS:
            return {
                ...state,
                cohorts: payload,
            };
        case actionTypes.GET_ARCHIVED_COHORTS:
            return {
                ...state,
                archiveCohorts: payload,
            };
        case actionTypes.GET_ALL_COHORTS_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload,
            };
        case actionTypes.ARCHIVE_COHORT_NOTIFICATIONS: {
            let notificationsCopy = [...state.notifications];
            const notificationIndexes = [];
            payload.forEach((id) => {
                const index = notificationsCopy.findIndex((element) => element.id === id);
                if (index !== -1) {
                    notificationIndexes.push(index);
                }
            });
            const filteredNotificationsCopy = notificationsCopy.filter(
                (_, index) => !notificationIndexes.includes(index)
            );
            return {
                ...state,
                notifications: [...filteredNotificationsCopy]
            };
        }
        case actionTypes.GET_MEMBERS_BY_COHORT_ID:
            return {
                ...state,
                members: payload,
            };
        case actionTypes.DELETE_NOTIFICATION: {
            let notificationsCopy = [...state.notifications];
            const notificationIndex = notificationsCopy.findIndex(element => element.id === payload);
            notificationsCopy.splice(notificationIndex, 1);
            return {
                ...state,
                notifications: [...notificationsCopy]
            };
        }
        case actionTypes.SCHEDULE_COHORTS: {
            let cohortsCopy = [...state.cohorts];
            const cohortIndex = cohortsCopy.findIndex(element => element.id === payload);
            cohortsCopy[cohortIndex].status = 'Scheduled'
            return {
                ...state,
                cohorts: [...cohortsCopy]
            };
        }
        case actionTypes.UPDATE_COHORT_MEMBERS: {
            let membersCopy = [...state.members];
            const memberIndex = membersCopy.findIndex(element => element.id === payload.id);
            if (payload.status === 'R') {
                membersCopy.splice(memberIndex, 1);
            }
            else {
                membersCopy[memberIndex] = { ...payload };
            }
            return {
                ...state,
                members: [...membersCopy]
            };
        }
        case actionTypes.GET_SENSITIVITY_SCORES:
            return {
                ...state,
                sensitivityScores: convertToSensivityData(payload),
            };
        case actionTypes.RESET_RISK_SCORES:
            return {
                ...state,
                sensitivityScores: initialState.sensitivityScores,
            };
        default:
            return state;
    }
};
